@media (max-width: 320px) {
  .form__name {
    height: 40px;
    margin-top: 10px;
  }
}
.input-size {
  width: 100%;
  height: 60px;
  border: 1px solid #5bc2dc;
  border-radius: 15px;
}
.input-hidden:focus {
  border: 1px solid #5bc2dc;
  outline-offset: 0px;
  outline: none;
}
.bg-login {
  background-image: linear-gradient(
    to bottom right,
    rgba(237, 247, 250, 1) 10%,
    rgb(237, 247, 250) 50%,
    rgb(237, 247, 250) 80%,
    rgba(237, 247, 250, 1) 90%
  );
  border-radius: 20px;
}
.input-hidden {
  border-top: 1px solid #ffffff;
  left: 10px;
  border: none;
  /* z-index: 999999; */
  text-align: left;
}
.input-hidden:focus {
  border: none;
  border-top: 0px solid #ffffff;
  box-shadow: 0 0 0px #ffffff;
  outline-offset: 0px;
  outline: none;
}
.border-choose {
  border-top: 2px solid #bdbdbd;
}
.otp-element {
  gap: 15px;
}
.otp-element > input {
  width: 52px !important;
  height: 52px !important;
  background: #ffffff;

  border-radius: 12px;
  border: 1px solid #98ebff;
  margin-bottom: 24px;
}

@media (max-width: 275px) {
  .otp-element {
    gap: 6px;
  }
  .otp-element > input {
    width: 30px !important;
    height: 30px !important;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 24px;
  }
}

@media (min-width: 276px) and(max-width: 320px) {
  .otp-element {
    gap: 8px;
  }
  .otp-element > input {
    width: 35px !important;
    height: 45px !important;
    background: #ffffff;
    border-radius: 12px;
    margin-bottom: 24px;
  }
}
@media (min-width: 320px) and (max-width: 376px) {
  .otp-element {
    gap: 8px;
  }
  .otp-element > input {
    width: 45px !important;
    height: 55px !important;
    background: #ffffff;
    border-radius: 15px;
    margin-bottom: 24px;
  }
}
@media (min-width: 376px) and (max-width: 455px) {
  .otp-element {
    gap: 5px;
  }
  .otp-element > input {
    width: 50px !important;
    height: 60px !important;
    background: #ffffff;
    border-radius: 15px;
    margin-bottom: 24px;
  }
}
@media (max-width: 320px) {
  .otp-element {
    gap: 5px;
  }
  .otp-elemet > input {
    width: 20px !important;
    height: 40px !important;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 24px;
  }
}
.checkbox-confirm-register {
  accent-color: #28afe9;
  width: 20px;
  height: 20px;
}
.border-itemlogin {
  border: 1px solid #5bc2dc;
  border-radius: 15px;
}
input[type="date"] {
  background-color: white;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: white;
  opacity: 1;
}

/* Đặt màu nền trắng cho Firefox */
input[type="date"]::-moz-focus-inner {
  background-color: white;
}

/* Đặt màu nền trắng cho các trình duyệt khác */
input[type="date"]::-ms-expand {
  background-color: white;
}
