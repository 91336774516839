.bg-color-header {
  background-image: linear-gradient(
    to bottom right,
    #5bc2dc 1%,
    #aaeff8 20%,
    #ffffff 45%,
    #ffffff 55%,
    #aaeff8 80%,
    #5bc2dc 100%
  );
}
.item-gift {
  box-shadow: 4px 6px 11px -3px rgba(16, 128, 156, 0.5);
}
.border-item {
  border-bottom: 4px solid #4e9abb;
}
.border-change {
  border-bottom: 3px solid #3b69ee;
  border-right: 3px solid #3b69ee;
}
.border-point {
  border-bottom: 2px solid #ffffff;
  border-top: 2px solid #ddf4fc;
  border-left: 2px solid #eff7fa;
  border-right: 2px solid #eff7fa;
}
.border-tc {
  border-bottom: 1px solid #b5e0ee;
  border-top: 1px solid #b5e0ee;
  border-left: 1px solid #b5e0ee;
  border-right: 1px solid #b5e0ee;
}
.border-title-exchangegift {
  border-bottom: 0.1px solid #e6e6e6;
  padding-bottom: 2px;
}
.border-bar {
  border: 2px solid rgb(215, 247, 255);
}
.border-base {
  border: 2px solid #ffffff;
}
.selected_day {
  color: #fff;
  background-color: #3ab4f1;
}
.border-brand {
  border: 1px solid #5bc2dc;
}
.banner-top-animation {
  animation: bannerTop 1s linear;
}
@keyframes bannerTop {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.banner-top-animationreverse {
  animation: bannerTopReverse 1s linear;
}
@keyframes bannerTopReverse {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
