.rotate-thunder {
  transform: rotate(-5deg);
}
.animation-thunder {
  animation: 1s fadeThunder ease-in infinite alternate-reverse;
}
@keyframes fadeThunder {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.animation-lightaqua {
  animation: lightAqua 1s linear infinite alternate-reverse;
}
@keyframes lightAqua {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}
