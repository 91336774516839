.font-italic-so {
  font-family: Sofia-Pro-Italic;
}
.font-bold-so {
  font-family: Sofia-Pro-Bold;
}
.font-medium-so {
  font-family: Sofia-Pro-Medium;
}
.font-semibold-so {
  font-family: Sofia-Pro-Semi-Bold;
}
.font-regular-so {
  font-family: Sofia-Pro-Regular;
}
.font-light-so {
  font-family: Sofia-Pro-Light;
}
.font-regular-greatvibes {
  font-family: GreatVibes-Regular;
}
.font-light-italic-so {
  font-family: Sofia-Pro-Light-Italic;
}
.font-medium-italic-so {
  font-family: Sofia-Pro-Medium-Italic;
}
.font-medium-air {
  font-family: Airsticke-Medium;
  /* AIRSTRIKE-VIE.OTF */
}
.color-dark-pepsi {
  color: #2d4e68;
}
.color-main-pepsi {
  color: #4e9abb;
}
.color-light-pepsi {
  color: #5bc2dc;
}
.border-bottom-line {
  border-bottom: 1px solid #979797;
}
@font-face {
  font-family: "Airsticke-Medium";
  src: url("../fonts/AIRSTRIKE-VIE.OTF");
}
@font-face {
  font-family: "Sofia-Pro-Italic";
  src: url("../fonts/Sofia-Pro-Italic.ttf");
}
@font-face {
  font-family: "Sofia-Pro-Light-Italic";
  src: url("../fonts/Sofia-Pro-Light-Italic.ttf");
}
@font-face {
  font-family: "Sofia-Pro-Medium-Italic";
  src: url("../fonts/Sofia-Pro-Medium-Italic.ttf");
}
@font-face {
  font-family: "Sofia-Pro-Semi-Bold";
  src: url("../fonts/Sofia-Pro-Semi-Bold.OTF");
}
@font-face {
  font-family: "Sofia-Pro-Bold";
  src: url("../fonts/Sofia-Pro-Bold.ttf");
}
@font-face {
  font-family: "Sofia-Pro-Medium";
  src: url("../fonts/Sofia-Pro-Medium.OTF");
}
@font-face {
  font-family: "Sofia-Pro-Regular";
  src: url("../fonts/Sofia-Pro-Regular.ttf");
}
@font-face {
  font-family: "Sofia-Pro-Light";
  src: url("../fonts/Sofia-Pro-Light.ttf");
}
@font-face {
  font-family: "GreatVibes-Regular";
  src: url("../fonts/GreatVibes-Regular.ttf");
}
.animate-bgresult {
  animation: fadeBGIN 2.2s linear infinite;
}
@keyframes fadeBGIN {
  from {
    transform: scale(0.6);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
.border-img-point {
  background: radial-gradient(
    circle,
    rgb(225, 245, 250) 0%,
    rgba(225, 245, 250, 0.9) 25%,
    rgba(255, 255, 255, 0) 70%
  );
  border-radius: 50%;
}
.border-img-gift {
  background: radial-gradient(
    circle,
    rgb(225, 245, 250) 0%,
    rgba(241, 248, 250, 0.6) 25%,
    rgba(255, 255, 255, 0) 70%
  );
  border-radius: 50%;
  height: 200px;
  width: 230px;
}
