.containerNotify__background {
  border-radius: 30px 30px 0 0;
  background: #fff9f4;
  overflow: hidden;
}
.containerNotify__background .containerNotify__background-list {
  overflow-y: auto;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.containerLuckyBox__background {
  border-radius: 15px;
  background: #fff9f4;
  overflow: hidden;
}
.containerLuckyBox__background .containerLuckyBox__background-list {
  overflow-y: auto;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.line-table table {
  font-size: 10px;
  width: 100%;
  margin-left: -15px;
  border-collapse: collapse; /* Kết hợp các đường viền của ô với nhau */
}

/* Định dạng các ô bảng (th và td) bên trong line-table */
.line-table th,
.line-table td {
  border: 1px solid black; /* Đường viền màu đen */
  text-align: center; /* Căn lề trái */
}
.line-tablesting th,
.line-tablesting td {
  border: 1px solid rgb(255, 255, 255); /* Đường viền màu đen */
  text-align: center; /* Căn lề trái */
  font-size: 11px;
  /* width: 80; */
}
/* Định dạng tiêu đề bảng (th) bên trong line-table */
.line-table th {
  background-color: #f2f2f2; /* Màu nền cho tiêu đề bảng */
}
@media (min-width: 400px) {
  .line-table table {
    width: 100%;
    margin-left: -5px;
    border-collapse: collapse; /* Kết hợp các đường viền của ô với nhau */
  }
  .line-table th,
  .line-table td {
    border: 1px solid black; /* Đường viền màu đen */
    padding-left: 5px;
    text-align: left; /* Căn lề trái */
  }
}
