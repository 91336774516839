.tab button:disabled {
  height: 40px;
  border-radius: 11px;
  /* border: 1px solid #98ebff; */
  background-color: #5bc2dc;
  width: 150px;
  color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-left: 20px;
  padding-right: 20px;
}
.tab button {
  cursor: pointer;
  text-align: center;
  color: #2d4e68;
  font-size: 14px;
  border-radius: 11px;
  background-color: #ffffff;
  padding: 12px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #4e9abb;
  width: 150px;
}
.tab {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
  border-radius: 16px;
  padding-bottom: 10px;
  padding-top: 0px;
}
.menu3tab button:disabled {
  height: 40px;
  border-radius: 11px;
  border: 0px solid #98ebff;
  background-color: #5bc2dc;
  width: 100px;
  color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* padding-left: 3px;
  padding-right: 3px; */
}
.menu3tab button {
  cursor: pointer;
  text-align: center;
  color: #2d4e68;
  font-size: 14px;
  border-radius: 11px;
  background-color: #ffffff;
  padding: 12px;
  padding-left: 1px;
  padding-right: 1px;
  border: 1px solid #4e9abb;
  width: 100px;
}
.menu3tab {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
  border-radius: 16px;
  padding-bottom: 10px;
  padding-top: 0px;
}
@media (min-width: 400px) {
  .menu3tab button {
    width: 110px;
  }
  .menu3tab button:disabled {
    width: 110px;
  }
}
.tab-home button:disabled {
  height: 40px;
  width: 100px;
  color: #2d4e68;
  /* border-bottom: 3px solid #5bc2dc; */
  margin-bottom: -5px;
}
.tab-home button {
  width: 100px;
  text-align: center;
  color: #818ba0;
  font-size: 14px;
  background-color: #fff9f4;
  padding-left: 0px;
  padding-right: 0px;
}
.tab-home {
  padding-bottom: 16px;
  padding-top: 8px;
}
.tab-exchange-gift button:disabled {
  height: 40px;
  width: 150px;
  color: #5bc2dc;
  /* border-bottom: 3px solid #5bc2dc; */
  margin-bottom: -5px;
}
.tab-exchange-gift button {
  width: 150px;
  text-align: center;
  color: #818ba0;
  font-size: 14px;
  /* background-color: #ffffff; */
  padding-left: 0px;
  padding-right: 0px;
}
.tab-exchange-gift {
  padding-bottom: 16px;
  padding-top: 8px;
}

.tab-exchange-gifts button:disabled {
  height: 40px;
  width: 120px;
  color: #2d4e68;
  /* border-bottom: 3px solid #5bc2dc; */
  margin-bottom: -5px;
}
.tab-exchange-gifts button {
  width: 120px;
  text-align: center;
  color: #818ba0;
  font-size: 14px;
  background-color: #ffffff;
  padding-left: 0px;
  padding-right: 0px;
}
.tab-exchange-gifts {
  padding-bottom: 16px;
  padding-top: 8px;
}
.tab-rating button:disabled {
  height: 60px;
  border-radius: 45%;
  background-color: #e1ecf8;
  width: 60px;
}
.tab-rating button {
  color: #e1ecf8;
  background-color: #ffffff;
}
.bg-navtab {
  box-shadow: 0px 4px 2px 2px rgba(0, 0, 0, 0.1) inset;
  border-radius: 100%;
}
.shadow-navcontrol {
  box-shadow: 0px 0px 5px 3px rgba(62, 175, 240, 0.726);
}
.shadow-navcontrol1 {
  box-shadow: 0px 0px 5px 5px rgba(0, 141, 223, 0.2);
}
.border-shadow-campaign {
  box-shadow: 7px 6px 7px 0px rgba(3, 116, 245, 0.2);
}
.border-itemcampaign {
  border: 2px solid #b5e0ee;
}
.bg-navglass {
  background: rgba(255, 255, 255, 0.7);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(8.1px);
  border: 0px solid rgba(255, 255, 255, 0.1);
}
.test {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../image/mask_nav.png) no-repeat center center;
  background-size: cover;
  filter: blur(10px);
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: url(../image/mask_nav.png);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: cover;
  -webkit-mask-image: url(../image/mask_nav.png);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: cover;
}
.border-tab {
  background-color: #013be2;
  border-radius: 100px;
}

.border-cancel {
  border: 2px solid #5bc2dc;
}

.point-ul ul {
  list-style-type: none; /* loại bỏ dấu đầu dòng mặc định của ul */
  padding-left: 0; /* đảm bảo không có lề trái cho ul */
}

.point-ul ul li {
  position: relative; /* để có thể định vị pseudo-element */
  padding-left: 20px; /* tạo khoảng cách giữa dấu chấm và nội dung của li */
}

.point-ul ul li::before {
  content: "\2022"; /* mã Unicode của dấu chấm (•) */
  position: absolute;
  left: 0;
  top: 0; /* đặt top là 0 để dấu chấm nằm ở đầu dòng */
}
.bg-blinker {
  background-color: #f63440;
  border-radius: 10px;
  animation: fadeBlink 1s linear infinite alternate-reverse;
  border: 3px solid rgba(250, 195, 195, 1);
}
.bg-rotation {
  background-color: #f63440;
  border-radius: 50px;
}
@keyframes fadeBlink {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1.2);
  }
}
.border-inglassphism {
  height: 70px;
  width: 70px;
  background-color: #ffffff;
  border-radius: 100px;
  opacity: 0.5;
}
.border-code-confirm {
  border: 1px solid #3ee3ef;
}
