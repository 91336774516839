.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-reponsive {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.textcampaign-reponsive {
  max-width: 340px; /* Giới hạn độ rộng của chuỗi */
  display: -webkit-box; /* Hiển thị theo dạng hộp để giới hạn số hàng */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Hiển thị tối đa 2 hàng */
  overflow: hidden; /* Ẩn phần nội dung thừa */
  text-overflow: ellipsis; /* Hiển thị dấu "..." nếu nội dung quá dài */
  white-space: normal; /* Cho phép xuống hàng */
}
.text-reponsive1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.box {
  width: 200px;
  height: 200px;
  /* background-color: lightblue; */
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  margin: 0 auto;
}

select {
  -webkit-appearance: none; /* Remove default appearance for WebKit browsers */
  -moz-appearance: none; /* Remove default appearance for Firefox */
  appearance: none; /* Remove default appearance */
  background-color: white; /* Set background color to white */
  color: black;
}
.noaccess-content {
  width: 98%;
  padding: 15px;
  border: 3px solid #f7de8c;
  border-radius: 8px;
  max-width: 500px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  /* or 144% */

  display: flex;
  align-items: center;
  text-align: center;
  background-color: white;
  color: black;
}
.bg-cover {
  background-image: url(../src/assets/image/desktop.png); /* Đường dẫn tới ảnh nền của bạn */
  background-repeat: no-repeat;
  background-size: cover; /* Đảm bảo background luôn phủ kín */
  background-position: center; /* Đặt vị trí hình ảnh ở giữa */
  height: 100vh; /* Chiều cao bằng với chiều cao viewport */
  width: 100%; /* Chiều rộng chiếm toàn bộ màn hình */
}
.page-enter {
  opacity: 0;
  transform: translateY(100%);
}
.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.page-exit {
  opacity: 1;
  transform: translateY(0);
}
.page-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 300ms, transform 300ms;
}
/* @media screen and (orientation: landscape) {
  body {
    display: none;
  }
} */
.check-reponsive {
  display: block;
}
@media (min-width: 435px) {
  .check-reponsive {
    display: none;
  }
}
