.content-popup-condition {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.border-contact {
  border: 2px solid #4e9abb;
  border-radius: 16px;
}
.button-primary {
  background-color: #5bc2dc;
}
.containerNotify__background {
  /* background: #fff; */
  overflow: hidden;
}
.containerNotify__background .containerNotify__background-list {
  overflow-y: scroll !important;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.bg-coundown {
  background: url(../image/Popup/bg-countdown.png);
  background-position: center;
  background-size: cover;
}
.popupGift__background {
  background: transparent;
  overflow: hidden;
}
.popupGift__background .popupGift__background-list {
  overflow-y: scroll !important;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.bg-main {
  background-image: linear-gradient(#b6e8fd, #50b1ca);
  width: 100%;
  min-height: 40vh;
  /* max-height: 40vh; */
}
.bg-contain {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow-y: scroll !important;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  min-height: 40vh;
  max-height: 60vh;
}
.bg-contain::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.input-hidden:focus {
  border: none;
  outline-offset: 0px;
  outline: none;
}
