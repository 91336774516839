div#bg {
  margin: 0px auto;
  position: relative;
  z-index: 2;
}

.mainbox {
  max-width: 430px;
  width: 100%;
  height: 91vmin;
  top: 0px;
  margin: 0 auto;
  z-index: 2;
  right: 0;
}

.box {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;

  overflow: hidden;
  transition: all ease 5s;
  box-sizing: border-box;
}

span.segment {
  width: 50%;
  height: 50%;
  display: inline-block;
  position: absolute;
}
.span1 {
  top: 98px;
  left: -35px;
}
.span2 {
  top: 78px;
  right: -40px;
}
.span3 {
  bottom: -35px;
  left: 90px;
}
.span4 {
  top: -20px;
  left: 75px;
}

span.segment.span1.color-1,
span.segment.span1.color-2 {
  transform: rotate(-110deg);
}

span.segment.span2.color-1,
span.segment.span2.color-2 {
  transform: rotate(65deg);
}

span.segment.span3.color-1,
span.segment.span3.color-2 {
  transform: rotate(160deg);
}

span.segment.span4.color-1,
span.segment.span4.color-2 {
  transform: rotate(-23deg);
}

.color-1 {
  font-weight: bold;
}
.color-2 {
  font-weight: bold;
}

.box2 {
  width: 100%;
  height: 100%;
  transform: rotate(-135deg);
}
.span1 i.segments {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 58%;
  left: 42%;
  transform: translate(-90%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}

.span2 i.segments {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 52%;
  transform: translate(-90%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}

.span3 i.segments {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 56%;
  left: 40%;
  transform: translate(-90%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}

.span4 i.segments {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 52%;
  left: 55%;
  transform: translate(-90%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}

.segments img {
  max-width: 60px;
  max-height: 60px;
  margin-top: 0px;
  top: 0px;
  /* right: 30px; */
}
.segments h6 {
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  left: 55px;
  line-height: 13px;
  text-align: center;
  top: -23px;
  width: 90px;
}
.spin {
  outline: none;
  position: absolute;
  /* top: 57%; */
  top: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  left: 50%;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
.spin > img {
  max-width: none !important;
}

button.spin {
  border-radius: 30px;
}

.mainbox.animate:after {
  animation: animateArrow 0.7s ease infinite;
}
@keyframes animateArrow {
  50% {
    right: -40px;
  }
}
@media only screen and (max-width: 360px) {
  .bg:before {
    left: -16px;
    background-position: -7px;
  }
  .mainbox {
    left: -15px;
  }
  div#bg:after {
    left: -11px;
  }
}
@media (min-width: 361px) and (max-width: 375px) {
  .bg:before {
    left: -20px;
  }
  .mainbox {
    left: 0;
  }
  div#bg:after {
    left: -5px;
  }
  .span1 i.segments {
    left: 50%;
  }
  .span2 i.segments {
    left: 46%;
  }
  .span3 i.segments {
    left: 40%;
    top: 50%;
  }
  .span4 i.segments {
    top: 48%;
    left: 59%;
  }
}
@media (min-width: 376px) and (max-width: 410px) {
  .span1 i.segments {
    left: 50%;
  }
  .span2 i.segments {
    left: 46%;
  }
  .span3 i.segments {
    left: 44%;
    top: 55%;
  }
  .span4 i.segments {
    top: 50%;
    left: 56%;
  }
}
@media (min-width: 411px) and (max-width: 418px) {
  .span1 i.segments {
    left: 45%;
    top: 53%;
  }
  .span2 i.segments {
    left: 48%;
  }
  .span3 i.segments {
    left: 41%;
    top: 55%;
  }
  .span4 i.segments {
    top: 50%;
    left: 56%;
  }
}
@media (min-width: 419px) and (max-width: 432px) {
  .span1 i.segments {
    left: 44%;
  }
  .span2 i.segments {
    left: 50%;
  }
  .span3 i.segments {
    left: 40%;
    top: 55%;
  }
  .span4 i.segments {
    top: 51%;
    left: 56%;
  }
}
.wheel-text {
  position: relative;
}
.btn-luotquay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;
  width: 100%;
  height: 48px;
  background: #f6de8b;
  border: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  color: #d73727;
  margin-top: 60px;
}

.hetluotquay-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;

  color: #f6de8b;
  margin-top: 20px;
}
.spin-remain-container {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spin-remain-container.container {
  min-height: 0;
}

.spin-remain-container .spin-remain {
  padding: 16px 32px;
  background: #efdb8e;
  border: 4px solid #eed163;
  border-radius: 16px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #b12125;
}

@media only screen and (max-width: 375px) {
  .segments h6 {
    font-size: 12px;
  }
  .segments img {
    max-width: 50px;
  }
  .span3 {
    bottom: -20px;
    left: 70px;
  }
  .span4 {
    top: -5px;
    left: 70px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .span4 {
    top: -0px;
    left: 60px;
  }
  .span3 {
    bottom: -10px;
    left: 60px;
  }
}
.border-popupgift {
  border: 9px solid #ffae38;
}
.background-result {
  background-image: url(../image/Popup/bg-confirm.png);
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  min-width: 100vw;
}
.click-animation {
  animation: fadeClick 0.6s linear infinite alternate-reverse;
}
@keyframes fadeClick {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.3);
  }
}
